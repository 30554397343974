<template>
  <BaseDialog
    :show="true"
    title="提醒"
    @confirm="$emit('confirm')"
    @cancel="$emit('close')"
    @close="$emit('close')"
  >
    <div class="pb-[40px]">
      <p>是否將 {{ configData.name }} {{ displayData.warnType }} ？</p>
      <p>新建立的訂單將{{ configData.enable ? '不會' : '會' }}自動分配預設狀態，請確認是否仍需{{ displayData.warnTypeConfirm }}？</p>
    </div>
  </BaseDialog>
</template>

<script>
import { computed, defineComponent } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'

export default defineComponent({
  name: 'ToggleCustomOrderStatusModal',
  components: { BaseDialog },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const displayData = computed(() => {
      return {
        warnType: props.configData.enable ? '關閉啟用' : '啟用',
        warnTypeConfirm: props.configData.enable ? '關閉' : '啟用',
      }
    })
    return { displayData }
  },
})
</script>
