<template>
  <BaseDialog
    :show="true"
    title="狀態調整詳情"
    width="576px"
    hideConfirm
    hideCancel
    @confirm="$emit('confirm')"
    @cancel="$emit('close')"
    @close="$emit('close')"
  >
    <div class="flex flex-col gap-[8px] text-sub">
      <div
        v-for="(item, idx) in displayData"
        :key="idx"
        class="flex items-cemter gap-[12px]"
      >
        <p class="font-medium">{{ item.label }}</p>
        <p>{{ item.value }}</p>
      </div>
    </div>
  </BaseDialog>
</template>

<script>
import { computed, defineComponent } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { get, map } from 'lodash'

export default defineComponent({
  name: 'StatusChangeRecordDetailModal',
  components: {
    BaseDialog,
  },
  props: {
    data: { type: Array, default: () => [] },
  },
  setup (props) {
    const displayTypeLabel = {
      create: '新增狀態',
      update: '更換狀態',
      delete: '刪除狀態',
    }

    const displayTypeValue = (actionType, data) => {
      if (actionType === 'create') return data.targetName
      if (actionType === 'update') return `${data.originName} 調整為 ${data.targetName || '–'}`
      if (actionType === 'delete') return `${data.originName} 指定為 ${data.targetName || '–'}`
    }
    const displayData = computed(() => {
      return map(props.data, (item) => {
        return {
          label: get(displayTypeLabel, item.action),
          value: displayTypeValue(item.action, item),
        }
      })
    })
    return { displayData }
  },
})
</script>

<style lang="postcss" scoped>

</style>
