<template>
  <div class="empty-setting-block section-block">
    <img
      width="100"
      style="margin: auto"
      src="@/assets/icon/nodata/no_data.svg"
    >
    <p class="pt-[8px] text-sub text-center">尚未建置自定義流程，請新增一筆自定義的預約流程。</p>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'EmptySettingBlock',
})
</script>

<style lang="postcss" scoped>

</style>
