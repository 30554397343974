import { admin2 } from './instance'

export const GetShopModelOperationLog = async ({
  shopId, modelName,
  recordId,
  start,
  limit,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopModelOperationLog`,
    params: {
      modelName,
      recordId,
      start,
      limit,
    },
  })
}

export const GetShopModelOperationLogCount = async ({
  shopId, modelName,
  recordId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopModelOperationLog/count`,
    params: {
      modelName,
      recordId,
    },
  })
}

export const FindShopModelOperationLog = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopModelOperationLog/${id}`,
  })
}
