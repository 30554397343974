<template>
  <div class="">
    <PageTitle
      title="自定義訂單狀態模組"
      icon="chevron_left"
      hideBtn
      @iconClick="$router.push({name: 'Parameters'})"
    />
    <div class="page-content-wrapper">
      <CustomOrderStatusTabs @change="onTabChange" />
      <p class="text-sub text-gray-80">{{ displayData.maxHint }}</p>
      <EmptySettingBlock v-if="!displayData.settings.length" />

      <div v-loading="!allLoaded" class="setting-block-list">
        <CustomOrderStatusSettingBlock
          v-for="(flowConfig, idx) in displayData.settings"
          :key="idx"
          :configData="flowConfig"
          @refresh="refresh"
        />
      </div>

      <ElInputWrapper v-if="displayData.settings.length < maxSettings" class="w-[152px]">
        <el-button type="primary" @click="$router.push({name: 'EditCustomOrderStatusSetting', params: {scope: curTab}})">
          新增{{ displayData.curScope }}流程
        </el-button>
      </ElInputWrapper>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onBeforeMount, set, computed, onActivated } from 'vue'
import CustomOrderStatusTabs from './components/CustomOrderStatusTabs.vue'
import CustomOrderStatusSettingBlock from './components/CustomOrderStatusSettingBlock.vue'
import EmptySettingBlock from './components/EmptySettingBlock.vue'
import ElInputWrapper from '@/components/ElInputWrapper.vue'
import { customOrderStatusModulesConfig } from '@/config/customOrderStatus'
import { useRouter } from 'vue-router/composables'
import { get, includes, values, omit } from 'lodash'
import { useFetch } from '@/use/fetch'
import { GetCustomFlow } from '@/api/customFlow'
import { useShop } from '@/use/shop'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'CustomOrderStatus',
  components: { CustomOrderStatusTabs, CustomOrderStatusSettingBlock, EmptySettingBlock, ElInputWrapper },
  setup (props) {
    const router = useRouter()
    const { simpleFetch } = useFetch()
    const { shopId } = useShop()
    const { checkAction } = usePermissions()
    const maxSettings = 3
    const mounted = ref(false)
    const statusSettingList = reactive({})

    const loaded = reactive({})
    const loading = ref(false)
    const allLoaded = computed(() => !includes(values(loaded), false))
    const displayData = computed(() => {
      const ss = statusSettingList[curTab.value] || []

      return {
        settings: ss,
        maxHint: `${get(customOrderStatusModulesConfig, `${curTab.value}.label`)} 至多可以有 ${maxSettings} 個自定義流程`,
        curScope: get(customOrderStatusModulesConfig, `${curTab.value}.label`),
      }
    })
    const displayTabs = computed(() => {
      const omitList = []
      if (!checkAction('admin.appointmentReservation.page')) omitList.push(customOrderStatusModulesConfig.appointment.value)
      if (!checkAction('adminView.memberStorePointExchangeOrder.page')) omitList.push(customOrderStatusModulesConfig.pointExchange.value)
      if (!checkAction('admin.ecommerceOrder.page')) omitList.push(customOrderStatusModulesConfig.ecommerce.value)
      if (!checkAction('adminView.memberStoreOrder.page')) omitList.push(customOrderStatusModulesConfig.memberStore.value)

      return omit(customOrderStatusModulesConfig, omitList)
    })
    const curTab = ref(get(Object.keys(displayTabs.value), '[0]'))
    const onTabChange = (newTab) => {
      curTab.value = newTab
    }

    const onEdit = (statusSettingBlock) => {
      router.push({
        name: 'EditCustomOrderStatusSetting',
        params: {
          scope: curTab.value,
          id: statusSettingBlock.id,
        },
      })
    }

    const refresh = (scope) => {
      simpleFetch(GetCustomFlow, {
        shopId: shopId.value,
        scope,
      }, (res) => {
        set(statusSettingList, scope, res)
      })
    }

    onBeforeMount(async () => {
      if (mounted.value) return
      for (const key in customOrderStatusModulesConfig) {
        set(loaded, key, false)
        set(statusSettingList, key, [])
        simpleFetch(GetCustomFlow, {
          shopId: shopId.value,
          scope: key,
        }, (res) => {
          set(statusSettingList, key, res)
          set(loaded, key, true)
        })
      }
      mounted.value = true
    })

    onActivated(async () => {
      for (const key in customOrderStatusModulesConfig) {
        set(loaded, key, false)
        set(statusSettingList, key, [])
        simpleFetch(GetCustomFlow, {
          shopId: shopId.value,
          scope: key,
        }, (res) => {
          set(statusSettingList, key, res)
          set(loaded, key, true)
        })
      }
    })

    return { maxSettings, curTab, onTabChange, onEdit, statusSettingList, displayData, loading, refresh, allLoaded }
  },
})
</script>

<style lang="postcss" scoped>
.page-content-wrapper {
  @apply flex flex-col gap-[24px];
}

.setting-block-list {
  @apply flex flex-col gap-[24px];
}
</style>
