<template>
  <div class="custom-order-status-setting-block section-block">
    <div class="setting-block-title">
      <p>{{ displayData.name }}</p>
      <div>
        <el-button v-if="useFeatures.changeLog" plain @click="modal.editRecord = true">檢視紀錄</el-button>
        <el-button type="primary" @click="onEdit">編輯流程</el-button>
      </div>
    </div>
    <FormInfo gap="22">
      <FormInfoItem
        width="250"
        title="流程啟用設定"
      >
        <el-switch
          :value="displayData.enabled"
          inactive-text="關閉"
          active-text="啟用"
          @change="onToggle"
        />
      </FormInfoItem>
      <FormInfoItem
        width="250"
        title="涵蓋狀態"
      >
        <div class="flex gap-[12px]">
          <Tag v-for="status in displayData.nodes" :key="status.id" type="action">
            {{ status.name }}
          </Tag>
        </div>
      </FormInfoItem>
    </FormInfo>

    <ToggleCustomOrderStatusModal
      v-if="modal.toggleWarn"
      :configData="configData"
      @confirm="confirmToggle"
      @close="modal.toggleWarn = false"
    />
    <CustomOrderStatusChangeLog
      v-if="modal.editRecord"
      :configData="configData"
      @close="modal.editRecord = false"
    />
  </div>
</template>

<script>
import { computed, defineComponent, reactive } from 'vue'
import FormInfo from '@/components/FormInfo/FormInfo.vue'
import FormInfoItem from '@/components/FormInfo/FormInfoItem.vue'
import ToggleCustomOrderStatusModal from './ToggleCustomOrderStatusModal.vue'
import CustomOrderStatusChangeLog from './CustomOrderStatusChangeLog.vue'
import { UpdateCustomFlow } from '@/api/customFlow'
import { useShop } from '@/use/shop'
import notifyMessage from '@/config/notifyMessage'
import { get } from 'lodash'
import { useRouter } from 'vue-router/composables'
import { sortOrder } from '@/utils/helper'
import { usePermissions } from '@/use/permissions'

const featureKeys = {
  changeLog: 'admin.shopModelOperationLog.find',
}

export default defineComponent({
  name: 'CustomOrderStatusSettingBlock',
  components: { FormInfo, FormInfoItem, ToggleCustomOrderStatusModal, CustomOrderStatusChangeLog },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const router = useRouter()
    const { checkAction } = usePermissions()
    const modal = reactive({
      toggleWarn: false,
      editRecord: false,
    })

    const useFeatures = computed(() => {
      return {
        changeLog: checkAction(featureKeys.changeLog),
      }
    })

    const displayData = computed(() => {
      return {
        name: get(props.configData, 'name'),
        enabled: get(props.configData, 'enable'),
        nodes: sortOrder(get(props.configData, 'nodes') || []),
      }
    })

    const onToggle = () => {
      modal.toggleWarn = true
    }

    const onEdit = () => {
      router.push({
        name: 'EditCustomOrderStatusSetting',
        params: {
          id: get(props.configData, 'id'),
          scope: get(props.configData, 'scope'),
        },
      })
    }

    const confirmToggle = async () => {
      const [, err] = await UpdateCustomFlow({
        shopId: shopId.value,
        ...props.configData,
        enable: !get(props.configData, 'enable'),
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success(notifyMessage.updateSuccess)
      modal.toggleWarn = false
      emit('refresh', props.configData.scope)
    }
    return { modal, onToggle, confirmToggle, onEdit, displayData, useFeatures }
  },
})
</script>

<style lang="postcss" scoped>
.custom-order-status-setting-block {}

.setting-block-title {
  @apply flex justify-between mb-[15px];
}
</style>
