<template>
  <div class="">
    <el-button-group>
      <el-button
        v-for="option in displayTabs"
        :key="option.value"
        class="group-btn"
        :class="{ active: active === option.value}"
        @click="selectTab(option.value)"
      >
        {{ option.label }}
      </el-button>
    </el-button-group>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from 'vue'
import { customOrderStatusModulesConfig } from '@/config/customOrderStatus'
import { usePermissions } from '@/use/permissions'
import { omit, get } from 'lodash'

export default defineComponent({
  name: 'CustomOrderStatusTabs',
  setup (props, { emit }) {
    const { checkAction } = usePermissions()

    const displayTabs = computed(() => {
      const omitList = []
      if (!checkAction('admin.appointmentReservation.page')) omitList.push(customOrderStatusModulesConfig.appointment.value)
      if (!checkAction('adminView.memberStorePointExchangeOrder.page')) omitList.push(customOrderStatusModulesConfig.pointExchange.value)
      if (!checkAction('admin.ecommerceOrder.page')) omitList.push(customOrderStatusModulesConfig.ecommerce.value)
      if (!checkAction('adminView.memberStoreOrder.page')) omitList.push(customOrderStatusModulesConfig.memberStore.value)

      return omit(customOrderStatusModulesConfig, omitList)
    })
    const active = ref(get(Object.keys(displayTabs.value), '[0]'))

    const selectTab = (tab) => {
      active.value = tab
      emit('change', tab)
    }

    return { active, customOrderStatusModulesConfig, selectTab, displayTabs }
  },
})
</script>

<style lang="postcss" scoped>
.active {
  @apply border border-primary-100 bg-primary-100 text-white;
}

::v-deep .el-button {
  @apply shadow-none;
}

::v-deep .el-button:hover {
  @apply bg-primary-100 border-primary-100 text-white;
}

.group-btn {
  @apply px-[28px];
}
</style>
